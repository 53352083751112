import vertexShaderSource from "../shaders/shader.vert";
import fragmentShaderSource from "../shaders/shader.frag";
import "regenerator-runtime/runtime";

import ProjectImage1 from "../img/image1.jpg";
import ProjectImage2 from "../img/image2.jpg";

const MatrixUtil = {
  // Création d'une matrice identité 3x3
  create: function () {
    return [1, 0, 0, 0, 1, 0, 0, 0, 1];
  },

  // Translation d'une matrice 3x3 par [tx, ty]
  translate: function (matrix, tx, ty) {
    const m = this.create();
    m[6] = tx;
    m[7] = ty;
    return this.multiply(matrix, m);
  },

  // Multiplier deux matrices 3x3
  multiply: function (a, b) {
    var a00 = a[0 * 3 + 0];
    var a01 = a[0 * 3 + 1];
    var a02 = a[0 * 3 + 2];
    var a10 = a[1 * 3 + 0];
    var a11 = a[1 * 3 + 1];
    var a12 = a[1 * 3 + 2];
    var a20 = a[2 * 3 + 0];
    var a21 = a[2 * 3 + 1];
    var a22 = a[2 * 3 + 2];
    var b00 = b[0 * 3 + 0];
    var b01 = b[0 * 3 + 1];
    var b02 = b[0 * 3 + 2];
    var b10 = b[1 * 3 + 0];
    var b11 = b[1 * 3 + 1];
    var b12 = b[1 * 3 + 2];
    var b20 = b[2 * 3 + 0];
    var b21 = b[2 * 3 + 1];
    var b22 = b[2 * 3 + 2];

    return [
      b00 * a00 + b01 * a10 + b02 * a20,
      b00 * a01 + b01 * a11 + b02 * a21,
      b00 * a02 + b01 * a12 + b02 * a22,
      b10 * a00 + b11 * a10 + b12 * a20,
      b10 * a01 + b11 * a11 + b12 * a21,
      b10 * a02 + b11 * a12 + b12 * a22,
      b20 * a00 + b21 * a10 + b22 * a20,
      b20 * a01 + b21 * a11 + b22 * a21,
      b20 * a02 + b21 * a12 + b22 * a22,
    ];
  },
  rotate: function (matrix, angleInRadians) {
    const c = Math.cos(angleInRadians);
    const s = Math.sin(angleInRadians);
    const m = [c, -s, 0, s, c, 0, 0, 0, 1];
    return this.multiply(matrix, m);
  },
};

const Ease = {
  linear: (t) => t,
  i1: (t) => 1 - Math.cos(t * (0.5 * Math.PI)),
  o1: (t) => Math.sin(t * (0.5 * Math.PI)),
  io1: (t) => -0.5 * (Math.cos(Math.PI * t) - 1),
  i2: (t) => t * t,
  o2: (t) => t * (2 - t),
  io2: (t) => (t < 0.5 ? 2 * t * t : (4 - 2 * t) * t - 1),
  i3: (t) => t * t * t,
  o3: (t) => --t * t * t + 1,
  io3: (t) =>
    t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
  i4: (t) => t * t * t * t,
  o4: (t) => 1 - --t * t * t * t,
  io4: (t) => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t),
  i5: (t) => t * t * t * t * t,
  o5: (t) => 1 + --t * t * t * t * t,
  io5: (t) => (t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t),
  i6: (t) => (0 === t ? 0 : 2 ** (10 * (t - 1))),
  o6: (t) => (1 === t ? 1 : 1 - 2 ** (-10 * t)),
  io6: (t) =>
    0 === t || 1 === t
      ? t
      : (t /= 0.5) < 1
      ? 0.5 * 2 ** (10 * (t - 1))
      : 0.5 * (2 - 2 ** (-10 * --t)),
};

const lerp = (a, b, x, easeFunction) => {
  return a + (b - a) * easeFunction(x);
};

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const findElementsWithNames = (names) => {
  return window._config.elements.filter((element) =>
    names.includes(element.name)
  );
};

const updateElementWithName = (name, key, value) => {
  window._config.elements.forEach((element) => {
    if (element.name === name) {
      element[key] = value;
    }
  });
};

const mouseIsOverImage = (mouseX, mouseY, element) => {
  const imageHeight = element.height;
  const imageWidth = element.width;

  if (
    mouseX >= element.x &&
    mouseX <= element.x + imageWidth &&
    mouseY >= element.y &&
    mouseY <= element.y + imageHeight
  ) {
    return true;
  }
  return false;
};

const splitTextIntoSpans = (
  id,
  value,
  containerId,
  spanClassName,
  spaceWidth
) => {
  const p = document.getElementById(id);
  const newDiv = document.createElement("div");

  newDiv.id = containerId;
  newDiv.style.display = "flex";

  for (let char of value) {
    const span = document.createElement("span");
    span.className = spanClassName;
    span.style.display = "flex";
    if (char === " ") {
      span.style.width = spaceWidth;
    }
    span.textContent = char;
    newDiv.appendChild(span);
  }

  p.replaceWith(newDiv);
};

const splitTextIntoDoubleSpans = (
  id,
  value,
  outerSpanClassName,
  spanClassName,
  spaceWidth
) => {
  const p = document.getElementById(id);
  const newDiv = document.createElement("div");

  newDiv.style.display = "inline-block";

  for (let char of value) {
    const outerSpan = document.createElement("span");
    const innerSpan = document.createElement("span");

    outerSpan.className = outerSpanClassName;
    innerSpan.className = spanClassName;
    innerSpan.style.display = "inline-block";
    innerSpan.textContent = char;

    if (char === " ") {
      innerSpan.style.width = spaceWidth;
    }

    outerSpan.appendChild(innerSpan);
    newDiv.appendChild(outerSpan);
  }

  p.replaceWith(newDiv);
};

class Images {
  constructor() {
    this.gl = window._config.gl;
    this.image = null;
  }

  async initImg(url) {
    await this.loadImage(url);
    return this.image;
  }

  loadImage(url) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = url;

      image.onload = () => {
        this.image = image;
        resolve();
      };

      image.onerror = () => {
        reject(new Error(`Failed to load image from url ${url}`)); // Reject the promise on any errors
      };
    });
  }
}

class GL {
  constructor() {
    this.gl =
      canvas.getContext("webgl", {
        antialias: !0,
        alpha: !0,
        dpr: Math.min(window.devicePixelRatio, 2),
      }) || canvas.getContext("experimental-webgl");
    this.initShader();
    this.initCanvas();
    window._config.gl = this.gl;
  }
  initShader() {
    this.vertexShader = webglUtils.createShader(
      this.gl,
      this.gl.VERTEX_SHADER,
      vertexShaderSource
    );
    this.fragmentShader = webglUtils.createShader(
      this.gl,
      this.gl.FRAGMENT_SHADER,
      fragmentShaderSource
    );
  }
  initCanvas() {
    webglUtils.resizeCanvasToDisplaySize(this.gl.canvas);
    this.gl.viewport(0, 0, this.gl.canvas.width, this.gl.canvas.height);
    this.gl.clearColor(0, 0, 0, 0);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);
  }
}

class Program {
  constructor(vertexShader, fragmentShader) {
    this.gl = window._config.gl;
    this.program = webglUtils.createProgram(
      this.gl,
      vertexShader,
      fragmentShader
    );
    window._config.program = this.program;
    this.gl.useProgram(this.program);
    this.positionAttributeLocation = this.getAttribLocation("a_position");
    this.texCoordLocation = this.getAttribLocation("a_texCoord");
    window._config.attribs = {
      positionAttributeLocation: this.positionAttributeLocation,
      texCoordLocation: this.texCoordLocation,
    };
    this.setupResolution();
  }
  getAttribLocation(name) {
    return this.gl.getAttribLocation(this.program, name);
  }
  getUniformLocation(name) {
    return this.gl.getUniformLocation(this.program, name);
  }
  setupResolution() {
    const u_resolutionLocation = this.getUniformLocation("u_resolution");
    this.gl.uniform2f(
      u_resolutionLocation,
      this.gl.canvas.width,
      this.gl.canvas.height
    );

    this.gl.enableVertexAttribArray(this.positionAttributeLocation);
    this.gl.enableVertexAttribArray(this.texCoordLocation);
    this.gl.vertexAttribPointer(
      this.positionAttributeLocation,
      2,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    this.gl.enable(this.gl.BLEND);
    this.gl.blendFuncSeparate(
      this.gl.SRC_ALPHA,
      this.gl.ONE_MINUS_SRC_ALPHA,
      this.gl.ONE,
      this.gl.ONE_MINUS_SRC_ALPHA
    );
  }
}

class ProjectPage {
  constructor() {
    this.isOn = false;

    this.currentScrollPosition = 0;
    this.startY = 0;
    this.smoothScrollY = 0;
    this.duration = 1000;
    this.t = 0;
    this.start = 0;
    this.elementsY = [];
    this.endOfScrollDirection = 0;

    this.listeners = [];
  }

  init(element, elementIndex) {
    this.isOn = false;
    const wheelListener = (event) => {
      if (this.isOn) this.onWheelScroll(event);
    };

    const clickListener = (event) => {
      if (this.isOn) {
        window._config.elements.forEach((element, elementIndex) => {
          if (
            mouseIsOverImage(event.clientX, event.clientY, element) &&
            element.status === "page header"
          ) {
            this.destroy();
            this.setItemInList(element, elementIndex);
          }
        });
      }
    };

    window.addEventListener("wheel", wheelListener);

    window.addEventListener("click", clickListener);

    this.listeners.push(
      {
        key: "wheel",
        event: wheelListener,
      },
      {
        key: "click",
        event: clickListener,
      }
    );

    window._config.state = "projectPage";

    this.setItemInPage(element, elementIndex);
  }

  onWheelScroll(event) {
    let invertedDelta = event.deltaY * -10;
    if (
      (event.deltaY < 0 && event.deltaY > -4) ||
      (event.deltaY > 0 && event.deltaY < 4)
    ) {
      return;
    }

    const pageHeader = window._config.elements.find(
      (el) => el.status === "page header"
    );
    const lastElement = window._config.elements
      .filter(({ status }) => status === "image in project")
      .reduce((max, current) => (current.y > max.y ? current : max));

    const pageHeaderDefaultY =
      pageHeader.default.y - pageHeader.height + pageHeader.default.height;

    if (
      (invertedDelta > 0 && this.endOfScrollDirection === -1) ||
      (invertedDelta < 0 && this.endOfScrollDirection === 1)
    ) {
      this.endOfScrollDirection = 0;
    }

    let needToBeRendered = false;

    if (this.endOfScrollDirection === 0) {
      this.currentScrollPosition = invertedDelta;
      needToBeRendered = true;
    }

    if (
      this.endOfScrollDirection !== 1 &&
      invertedDelta > 0 &&
      this.currentScrollPosition + pageHeader.y > pageHeaderDefaultY
    ) {
      this.currentScrollPosition = pageHeaderDefaultY - pageHeader.y;
      this.endOfScrollDirection = 1;
      needToBeRendered = true;
    }
    if (
      this.endOfScrollDirection !== -1 &&
      invertedDelta < 0 &&
      this.currentScrollPosition + lastElement.y < pageHeaderDefaultY
    ) {
      this.currentScrollPosition = pageHeaderDefaultY - lastElement.y;
      this.endOfScrollDirection = -1;
      needToBeRendered = true;
    }

    if (needToBeRendered) {
      this.startY = 0;
      this.start = performance.now();
      window._config.elementsY = window._config.elements.map((el) => el.y);
      window.requestAnimationFrame((ts) => this.animateScroll(ts));
    }
  }

  animateScroll = (timestamp) => {
    this.t =
      (timestamp - this.start < 0 ? 0 : timestamp - this.start) / this.duration;

    this.smoothScrollY = lerp(
      this.startY,
      this.currentScrollPosition,
      this.t,
      Ease.o6
    );

    window._config.elements.forEach((el, elIndex) => {
      if (
        (el.status === "image in project" || el.status === "page header") &&
        this.smoothScrollY !== 0
      ) {
        let newY = window._config.elementsY[elIndex] + this.smoothScrollY;

        window._config.elements[elIndex]["y"] = newY;
        window._config.elements[elIndex].updateValues();
      }
    });

    if (this.t < 1) {
      window.requestAnimationFrame((ts) => this.animateScroll(ts));
    } else {
      this.startY = 0;
      this.currentScrollPosition = 0;
    }
  };

  setItemInList(element, elementIndex) {
    this.mode = "list";

    new Animate(elementIndex, 900, "y", element.default.y, Ease.io6);
    new Animate(elementIndex, 900, "height", element.default.height, Ease.io6);
    new Animate(elementIndex, 900, "width", element.default.width, Ease.io6);
    window._config.elements[elementIndex].status = "list";

    const listedElementToMove = window._config.elements.filter(
      (element) => element.status === "list"
    );

    listedElementToMove.forEach((el) => {
      new Animate(el.index, 900, "x", el.default.x, Ease.io6);
    });

    const listedElementInPageToDelete = window._config.elements.filter(
      (el) => el.status === "image in project"
    );
    listedElementInPageToDelete.forEach((el) => {
      new Animate(el.index, 900, "y", el.default.y, Ease.io6);
      const timeout = setTimeout(() => {
        updateElementWithName(el.name, "isDisplayed", false);
      }, 900);
      el.timeout = timeout;
    });

    const titleContainer = document.querySelector("#title_animate_container");
    const projectNameContainer = document.querySelector(
      "#project_name_animate_container"
    );

    titleContainer.style.transform = `translateY(0px)`;
    projectNameContainer.style.transform = `translateY(0px)`;

    const titleLetters = document.querySelectorAll(".title_char");
    const projectNameLetters = document.querySelectorAll(".project_name_char");

    const titleContainerWidth = Array.from(titleLetters).reduce((acc, el) => {
      if (el.textContent === "[" && el.textContent === "]") return acc + 0;
      return acc + el.getBoundingClientRect().width;
    }, 0);

    const projectNameWidth = Array.from(projectNameLetters).reduce(
      (acc, el) => {
        return acc + el.getBoundingClientRect().width;
      },
      0
    );

    titleLetters.forEach((letter, index) => {
      if (letter.textContent !== "[" && letter.textContent !== "]")
        setTimeout(
          () => {
            letter.style.transform = "translateY(0px)";
          },
          titleContainerWidth - projectNameWidth < 0 ? 0 : 250
        );

      if (letter.textContent === "[") {
        const pxDiff = (titleContainerWidth - projectNameWidth) / 2 - 30;
        setTimeout(
          () => {
            letter.style.transform = `translateX(0px)`;
          },
          pxDiff > 0 ? 0 : 250
        );
      }
      if (letter.textContent === "]") {
        const pxDiff = (projectNameWidth - titleContainerWidth) / 2 + 30;

        setTimeout(
          () => {
            letter.style.transform = `translateX(0px)`;
          },
          pxDiff < 0 ? 0 : 250
        );
      }
    });
    projectNameLetters.forEach((letter, index) => {
      letter.style.transform = "translateY(150px)";
    });
  }

  setItemInPage(element, elementIndex) {
    // const newImageWidth = window.innerWidth / 1.5;
    // const newImageHeight = window.innerHeight / 1.5;
    const newImageWidth = 700;
    const newImageHeight = newImageWidth / 1.7452006981;
    const newX = window.innerWidth / 2 - newImageWidth / 2;
    const newY = element.default.y - newImageHeight + element.default.height;

    new Animate(elementIndex, 900, "height", newImageHeight, Ease.io6);
    new Animate(elementIndex, 900, "width", newImageWidth, Ease.io6);
    new Animate(elementIndex, 900, "x", newX, Ease.io6);
    new Animate(elementIndex, 900, "y", newY, Ease.io6);

    window._config.elements[elementIndex].status = "page header";

    // Move other listed elements in the menu
    const listedElementToMove = window._config.elements.filter(
      (el) => el.status === "list"
    );
    listedElementToMove.forEach((el) => {
      if (el.name !== element.name) {
        if (el.x < element.x) {
          new Animate(
            el.index,
            900,
            "x",
            -el.width - window.innerWidth / 2 - el.x,
            Ease.io6
          );
        } else {
          new Animate(el.index, 900, "x", window.innerWidth + el.x, Ease.io6);
        }
      }
    });

    //Display the new image
    const newElementsToDisplay = window._config.elements.filter(
      (el) => el.linkedTo === element.name
    );
    newElementsToDisplay.forEach((el, elIndex) => {
      if (el.timeout) {
        clearTimeout(el.timeout);
      }
      updateElementWithName(el.name, "isDisplayed", true);

      el.status = "image in project";
      el.x = newX;
      el.y = window.innerHeight;
      el.default = {
        x: newX,
        y: window.innerHeight,
        width: newImageWidth,
        height: newImageHeight,
      };

      new Animate(el.index, 0, "x", newX, Ease.io6);
      new Animate(
        el.index,
        900,
        "y",
        newY + newImageHeight * (elIndex + 1) + 20 * (elIndex + 1),
        Ease.io6
      );
      new Animate(el.index, 0, "width", newImageWidth, Ease.io6);
      new Animate(el.index, 0, "height", newImageHeight, Ease.io6);
    });

    setTimeout(() => {
      this.isOn = true;
    }, 200);

    const titleContainer = document.querySelector("#title_animate_container");
    const projectNameContainer = document.querySelector(
      "#project_name_animate_container"
    );
    Array.from(projectNameContainer.querySelectorAll("span")).forEach(
      (span) => {
        span.remove();
      }
    );

    for (let letter of element.name) {
      let span = document.createElement("span");
      span.textContent = letter;
      span.className = "project_name_char animatedText";
      span.style =
        "display: flex; transition: 0.9s cubic-bezier(0.77, 0, 0.175, 1); transform: translateY(150px); min-width: 10px;";
      projectNameContainer.appendChild(span);
    }

    const titleLetters = document.querySelectorAll(".title_char");
    const projectNameLetters = document.querySelectorAll(".project_name_char");

    titleContainer.style.transform = `translateY(-${newImageHeight - 300}px)`;
    projectNameContainer.style.transform = `translateY(-${
      newImageHeight - 300
    }px)`;

    const titleContainerWidth = Array.from(titleLetters).reduce((acc, el) => {
      if (el.textContent === "[" && el.textContent === "]") return acc + 0;
      return acc + el.getBoundingClientRect().width;
    }, 0);

    const projectNameWidth = Array.from(projectNameLetters).reduce(
      (acc, el) => {
        return acc + el.getBoundingClientRect().width;
      },
      0
    );

    projectNameLetters.forEach((letter, index) => {
      letter.style.transition = `all 0.9s cubic-bezier(0.770, 0.000, 0.175, 1.000)`;
      setTimeout(
        () => {
          letter.style.transform = "translateY(0px)";
        },
        titleContainerWidth - projectNameWidth > 0 ? 0 : 250
      );
    });

    titleLetters.forEach((letter, index) => {
      letter.style.transition = `all 0.9s cubic-bezier(0.770, 0.000, 0.175, 1.000)`;
      if (letter.textContent !== "[" && letter.textContent !== "]") {
        letter.style.transform = "translateY(-150px)";
      }

      if (letter.textContent === "[") {
        const pxDiff = (titleContainerWidth - projectNameWidth) / 2 - 30;
        setTimeout(
          () => {
            letter.style.transform = `translateX(${pxDiff}px)`;
          },
          pxDiff > 0 ? 250 : 0
        );
      }

      if (letter.textContent === "]") {
        const pxDiff = (projectNameWidth - titleContainerWidth) / 2 + 30;

        setTimeout(
          () => {
            letter.style.transform = `translateX(${pxDiff}px)`;
          },
          pxDiff < 0 ? 250 : 0
        );
      }
    });
  }

  destroy() {
    this.listeners.forEach(({ key, event }) => {
      window.removeEventListener(key, event);
    });
    this.listeners = [];
    setTimeout(() => {
      window._config.state = "loader";
    }, 200);
  }
}

class Loader {
  constructor() {
    window._config.elements = window._config.elements;
    this.init();

    this.mode = "list";
    window._config.state = "loader";

    this.ProjectPage = new ProjectPage();
    this.Element = new Elements();
    this.Image = new Images();
  }

  init() {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const imageHeight = windowHeight / 4;
    const imageWidth = imageHeight / 1.4;
    const imageCenterX = windowWidth / 2 - imageWidth / 2;
    const deltaBetweenImage = imageWidth / 10;
    const durationStackImage = 1500;

    let even = 0;
    let uneven = 0;

    if (window._config.elements.length % 2 === 0) {
      even = window._config.elements.length / 2 - 1;
      uneven = window._config.elements.length / 2;
    } else {
      even = (window._config.elements.length - 1) / 2;
      uneven = (window._config.elements.length - 1) / 2;
    }
    window._config.elements.forEach((element) => {
      updateElementWithName(element.name, "isDisplayed", true);
    });

    window._config.elements.forEach((element, elementIndex) => {
      const loadingPourcentage = Math.round(
        (elementIndex / (window._config.elements.length - 1)) * 100
      );
      const loadingElement = document.querySelector("#loader");

      const deltaDelay = 30 * elementIndex + 300;
      const invertedIndex =
        (elementIndex - window._config.elements.length + 1) * -1;

      setTimeout(() => {
        let x = getRandomInt(-50, 50);
        let y = getRandomInt(-50, 50);
        let rotate = getRandomInt(-15, 15);

        if (elementIndex === window._config.elements.length - 1) {
          x = 0;
          y = 0;
          rotate = 0;
        }

        new Animate(element.index, 0, "width", imageWidth, Ease.io6);
        new Animate(element.index, 0, "height", imageHeight, Ease.io6);
        new Animate(
          element.index,
          0,
          "x",
          windowWidth / 2 - imageWidth / 2 + x,
          Ease.io6
        );
        new Animate(
          element.index,
          0,
          "y",
          windowHeight / 2 - imageHeight / 2 + y,
          Ease.io6
        );
        new Animate(element.index, 0, "zIndex", elementIndex, Ease.io6);
        new Animate(element.index, 0, "rotation", rotate, Ease.io6);

        loadingElement.textContent = `${loadingPourcentage}%`;
      }, (durationStackImage / window._config.elements.length) * elementIndex);

      setTimeout(() => {
        let x = 0;
        let y = windowHeight - imageHeight - imageHeight / 3;

        if (elementIndex === window._config.elements.length - 1) {
          x = imageCenterX;
        } else {
          let deltaCenterX = 0;

          if (invertedIndex % 2 === 0) {
            deltaCenterX = (deltaBetweenImage + imageWidth) * even;
            even -= 1;
          } else {
            deltaCenterX = -1 * ((deltaBetweenImage + imageWidth) * uneven);
            uneven -= 1;
          }
          x = imageCenterX + deltaCenterX;
        }
        new Animate(element.index, 900 + deltaDelay, "x", x, Ease.io6);
        new Animate(element.index, 900 + deltaDelay, "y", y, Ease.io6);
        new Animate(element.index, 900 + deltaDelay, "rotation", 0, Ease.io6);

        element.default = {
          x,
          y,
          width: imageWidth,
          height: imageHeight,
        };
        element.status = "list";
      }, (durationStackImage / window._config.elements.length) * (window._config.elements.length + 1));
    });

    setTimeout(() => {
      const titleLetters = document.querySelectorAll(".title_char");

      titleLetters.forEach((letter, index) => {
        const middleIndex = Math.floor((titleLetters.length - 1) / 2);
        const delay = (middleIndex - Math.abs(middleIndex - index)) * 15;
        setTimeout(() => {
          letter.style.transform = "translateY(0px)";
        }, delay);
      });

      const headerPortfolioChar = document.querySelectorAll(
        ".header-portfolio_char"
      );
      const headerAvailableChar = document.querySelectorAll(
        ".header-available_char"
      );
      [...headerPortfolioChar, ...headerAvailableChar].forEach(
        (letter, index) => {
          setTimeout(() => {
            letter.style.transform = "translateX(0px)";
          }, index * 20);
        }
      );

      const loaderPourcentage = document.querySelector("#loader");
      loaderPourcentage.style.transform = "translateY(-100px)";
    }, (durationStackImage / window._config.elements.length) * (window._config.elements.length + 1));

    setTimeout(() => {
      this.initEvent();
    }, (durationStackImage / window._config.elements.length) * (window._config.elements.length + 1) + 900 + 30 * window._config.elements.length);
  }

  initEvent() {
    window.addEventListener("mousemove", (event) => {
      if (window._config.state === "loader") {
        let isHovering = false;
        window._config.elements.forEach((element) => {
          if (
            mouseIsOverImage(event.clientX, event.clientY, element) &&
            element.status === "list"
          ) {
            isHovering = true;
          }
        });

        if (isHovering) {
          document.body.style.cursor = "pointer";
        } else {
          document.body.style.cursor = "default";
        }
      }
    });

    window.addEventListener("click", async (event) => {
      if (window._config.state === "loader") {
        const hoveredElement = window._config.elements.find((element) =>
          mouseIsOverImage(event.clientX, event.clientY, element)
        );

        if (hoveredElement) {
          window._config.elements = window._config.elements.filter(
            (el) => el.linkedTo === "" || el.linkedTo === hoveredElement.name
          );

          const childrensToCreate = pages[
            hoveredElement.index
          ].childrens.filter((el) => {
            return (
              window._config.elements.find(
                (element) => element.name === el.childrenName
              ) === undefined
            );
          });

          const elementsToCreate = await Promise.all(
            childrensToCreate.map(async (el) => {
              const loadedImage = await this.Image.initImg(el.image);
              return {
                image: loadedImage,
                name: el.childrenName,
                linkedTo: hoveredElement.name,
              };
            })
          );

          this.Element.createElements(elementsToCreate);
          this.ProjectPage.init(hoveredElement, hoveredElement.index);
        }
      }
    });

    window.addEventListener("wheel", (event) => {
      if (this.mode === "page") {
        this.onWheelScroll(event);
      }
    });
  }
}

class Renderer {
  constructor() {
    this.gl = window._config.gl;
    this.draw();
    new Loader();
  }

  draw() {
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    const sortedElements = [...window._config.elements].sort((a, b) => {
      return a.zIndex > b.zIndex ? 1 : -1;
    });

    sortedElements.forEach((element) => {
      if (element.image) {
        const u_matrixLocation = this.gl.getUniformLocation(
          window._config.program,
          "u_matrix"
        );
        this.gl.uniformMatrix3fv(u_matrixLocation, false, element.matrix);

        this.gl.bindTexture(this.gl.TEXTURE_2D, element.texture);
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, element.texCoordBuffers);
        this.gl.vertexAttribPointer(
          window._config.attribs.texCoordLocation,
          2,
          this.gl.FLOAT,
          false,
          0,
          0
        );

        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, element.positionBuffers);
        this.gl.vertexAttribPointer(
          window._config.attribs.positionAttributeLocation,
          2,
          this.gl.FLOAT,
          false,
          0,
          0
        );

        this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, element.indexBuffers);
        this.gl.drawElements(
          this.gl.TRIANGLES,
          element.indexCount,
          this.gl.UNSIGNED_SHORT,
          0
        );
      }
    });

    setTimeout(() => {
      requestAnimationFrame(() => this.draw());
    }, 10);
  }
}

class Animate {
  constructor(elementIndex, duration, varName, varData, easeFunction) {
    this.elementIndex = elementIndex;
    this.duration = duration;
    this.varName = varName;
    this.varData = varData;
    this.easeFunction = easeFunction;
    this.start = performance.now();
    this.t = 0;
    this.isFinished = false;

    this.animateData = {
      start: window._config.elements[elementIndex][varName],
      end: varData,
    };

    window.requestAnimationFrame((ts) => this.run(ts));
  }

  run(timestamp) {
    if (this.duration) {
      this.t =
        (timestamp - this.start < 0 ? 0 : timestamp - this.start) /
        this.duration;

      window._config.elements[this.elementIndex][this.varName] = lerp(
        this.animateData.start,
        this.animateData.end,
        this.t,
        this.easeFunction
      );

      window._config.elements[this.elementIndex].updateValues();

      if (this.t < 1) {
        window.requestAnimationFrame((ts) => this.run(ts));
      } else {
        this.isFinished = true;
      }
    } else {
      window._config.elements[this.elementIndex][this.varName] =
        this.animateData.end;
      window._config.elements[this.elementIndex].updateValues();
      this.isFinished = true;
    }
  }

  changeValue(varName, varData) {
    this.isFinished = false;
    this.animateData = {
      start: window._config.elements[this.elementIndex][varName],
      end: varData,
    };
    this.start = performance.now();
    this.t = 0;
    this.varName = varName;
    this.varData = varData;
    window.requestAnimationFrame((ts) => this.run(ts));
  }
}

class Elements {
  constructor() {
    this.gl = window._config.gl;
  }

  createElements(elementsToCreate) {
    elementsToCreate.forEach(({ image, name, linkedTo }) => {
      this.initElement(image, name, linkedTo);
    });
  }

  initElement(image, name, linkedTo) {
    const index = window._config.elements.length;
    window._config.elements.push({
      index: window._config.elements.length,
      name: name,
      linkedTo: linkedTo,
      isDisplayed: false,
      type: "img",
      image: image,
      texture: this.initTexture(image),
      positionBuffers: [],
      texCoordBuffers: [],
      indexBuffers: [],
      x: 0,
      y: 0,
      height: 0,
      width: 0,
      zIndex: 0,
      rotation: 0,
      updateValues: () => this.computeElementToWebgl(index),
    });

    this.computeElementToWebgl(index);
  }

  computeElementToWebgl(index) {
    const { x, y, height, width, rotation, image } =
      window._config.elements[index];

    let matrix = this.computeImageDatas(0, 0);

    matrix = MatrixUtil.translate(matrix, x, y);
    matrix = MatrixUtil.rotate(matrix, (rotation * Math.PI) / 180);
    window._config.elements[index].matrix = matrix;

    const baseVerticles = [0, 0, width, 0, 0, height, width, height];

    window._config.elements[index].positionBuffers =
      this.createBufferAndSetData(this.gl, baseVerticles);

    const aspectRatioImg = image.width / image.height;
    const aspectRatioContainer = width / height;
    let texX = 0,
      texY = 0,
      texW = 1,
      texH = 1;

    if (aspectRatioImg > aspectRatioContainer) {
      // L'image est relativement plus large que le conteneur
      texW = aspectRatioContainer / aspectRatioImg;
      texX = (1 - texW) / 2;
    } else {
      // L'image est relativement plus haute que le conteneur
      texH = aspectRatioImg / aspectRatioContainer;
      texY = (1 - texH) / 2;
    }

    const tex = [
      texX,
      texY,
      texX + texW,
      texY,
      texX,
      texY + texH,
      texX + texW,
      texY + texH,
    ];
    window._config.elements[index].texCoordBuffers =
      this.createBufferAndSetData(this.gl, tex);

    const indices = [0, 1, 2, 2, 1, 3];
    window._config.elements[index].indexBuffers =
      this.createIndexBufferAndSetData(this.gl, indices);
    window._config.elements[index].indexCount = indices.length;
  }

  initTexture(image) {
    const texture = this.gl.createTexture();
    this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
    this.gl.texParameteri(
      this.gl.TEXTURE_2D,
      this.gl.TEXTURE_WRAP_S,
      this.gl.CLAMP_TO_EDGE
    );
    this.gl.texParameteri(
      this.gl.TEXTURE_2D,
      this.gl.TEXTURE_WRAP_T,
      this.gl.CLAMP_TO_EDGE
    );
    this.gl.texParameteri(
      this.gl.TEXTURE_2D,
      this.gl.TEXTURE_MIN_FILTER,
      this.gl.LINEAR
    );
    this.gl.texParameteri(
      this.gl.TEXTURE_2D,
      this.gl.TEXTURE_MAG_FILTER,
      this.gl.LINEAR
    );
    this.gl.texImage2D(
      this.gl.TEXTURE_2D,
      0,
      this.gl.RGBA,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      image
    );
    return texture;
  }

  createBufferAndSetData(gl, bufferData) {
    const buffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array(bufferData),
      gl.STATIC_DRAW
    );
    return buffer;
  }

  createIndexBufferAndSetData(gl, indices) {
    const buffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffer);
    gl.bufferData(
      gl.ELEMENT_ARRAY_BUFFER,
      new Uint16Array(indices),
      gl.STATIC_DRAW
    );
    return buffer;
  }

  computeImageDatas(x, y) {
    const matrix = MatrixUtil.create();
    return MatrixUtil.translate(matrix, x, y);
  }
}

class Main {
  constructor() {
    this.init();
  }
  async init() {
    window._config.elements = [];

    const { vertexShader, fragmentShader } = new GL();
    new Program(vertexShader, fragmentShader);

    const image = new Images();

    const elements = new Elements();
    const elementsToCreate = await Promise.all(
      pages.map(async (el) => {
        const loadedImage = await image.initImg(el.image);
        return {
          image: loadedImage,
          name: el.pageName,
          linkedTo: "",
        };
      })
    );
    elements.createElements(elementsToCreate);

    splitTextIntoSpans(
      "title",
      "[ NICOLAS VÉRON ]",
      `title_animate_container`,
      `title_char animatedText`,
      "10px"
    );

    splitTextIntoSpans(
      "project_name",
      "TEST",
      `project_name_animate_container`,
      `project_name_char animatedText`,
      "10px"
    );

    splitTextIntoDoubleSpans(
      "header-portfolio",
      "PORTFOLIO 2024",
      "header-portfolio_container",
      "header-portfolio_char animatedText",
      "4px"
    );

    splitTextIntoDoubleSpans(
      "header-available",
      "AVAILABLE NOV.24",
      "header-available_container",
      "header-available_char animatedText",
      "4px"
    );

    new Renderer();
  }
}

function main() {
  new Main();
}

const pages = [
  {
    pageName: "LEADER GRANITS",
    image: ProjectImage1,
    childrens: [
      {
        childrenName: "project1_1",
        image: ProjectImage2,
      },
      {
        childrenName: "project1_2",
        image: ProjectImage2,
      },
      {
        childrenName: "project1_3",
        image: ProjectImage2,
      },
    ],
  },
  {
    pageName: "KORAIL",
    image: ProjectImage1,
    childrens: [
      {
        childrenName: "project1_1",
        image: ProjectImage2,
      },
      {
        childrenName: "project1_2",
        image: ProjectImage2,
      },
      {
        childrenName: "project1_3",
        image: ProjectImage2,
      },
    ],
  },
  {
    pageName: "LITTLE BIG ROAD",
    image: ProjectImage1,
    childrens: [
      {
        childrenName: "project1_1",
        image: ProjectImage2,
      },
      {
        childrenName: "project1_2",
        image: ProjectImage2,
      },
      {
        childrenName: "project1_3",
        image: ProjectImage2,
      },
    ],
  },
  {
    pageName: "HERMES",
    image: ProjectImage1,
    childrens: [
      {
        childrenName: "project1_1",
        image: ProjectImage2,
      },
      {
        childrenName: "project1_2",
        image: ProjectImage2,
      },
      {
        childrenName: "project1_3",
        image: ProjectImage2,
      },
    ],
  },
  {
    pageName: "LE SUPER PROJET DE LA MORT",
    image: ProjectImage1,
    childrens: [
      {
        childrenName: "project1_1",
        image: ProjectImage2,
      },
      {
        childrenName: "project1_2",
        image: ProjectImage2,
      },
      {
        childrenName: "project1_3",
        image: ProjectImage2,
      },
    ],
  },
];

main();
